import React from 'react'
import {
    ThemeProvider,
    Box,
    Divider,
    Grid,
    Typography,
    styled
} from '@mui/material'
import { theme } from './utils/Theme'
import ProjectCard from './components/ProjectCard'
import { GenericButton, SocialMediaIcons } from './components/Buttons'

// Styled components
const StyledHomePanel = styled(Box)({
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

const StyledHomePanelContent = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(18, 12, 12)
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(8, 4)
    }
}))

// Load all projects from .json file and filter featured projects
const allProjects = require('./content/allProjects.json')
const featuredProjects = allProjects.filter(
    (project: any) => project.featured == 'Yes'
)
const featuredProjectCards = featuredProjects.map((featuredProject: any) => (
    <Grid item xs={10} lg={6}>
        {ProjectCard(featuredProject)}
    </Grid>
))

// Main function
function HomePage() {
    return (
        <ThemeProvider theme={theme}>
            {/*Home Panel*/}
            <StyledHomePanel sx={{ backgroundColor: 'background.default' }}>
                <StyledHomePanelContent>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            {/*TODO: Replace screen.width with theme.breakpoints*/}
                            {screen.width <= 600 ? (
                                <Typography
                                    variant="h2"
                                    textTransform="uppercase"
                                    color="text.primary"
                                >
                                    Samantha
                                    <br />
                                    Yom
                                </Typography>
                            ) : (
                                <Typography
                                    variant="h1"
                                    textTransform="uppercase"
                                    color="text.primary"
                                >
                                    Samantha Yom
                                </Typography>
                            )}
                            <Typography variant="body1" color="text.primary">
                                Last seen refusing to shut up about language and
                                technology, or the latest app she stumbled on.
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledHomePanelContent>
            </StyledHomePanel>

            {/*About Panel*/}
            <StyledHomePanel sx={{ backgroundColor: 'secondary.main' }}>
                <StyledHomePanelContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={5} justifyContent="center">
                            <Typography
                                variant="h2"
                                textTransform="uppercase"
                                color="text.primary"
                                align="center"
                                marginBottom={theme.spacing(3)}
                            >
                                Hello I'm Sam 👋🏻
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                align="center"
                            >
                                Typical dog lover, shameless Notion fan and
                                Youth Olympic Gold medalist-turned-couch
                                athlete.
                                <br />
                                <br />
                                I’m a Linguistics and Psychology major with
                                absolutely no plans on becoming a polyglot or
                                mind reader. I’ve figured that it’s the things
                                that lie at the intersection of linguistics,
                                machine learning and AI that really get me
                                excited and that I hope to explore.
                            </Typography>
                            <Grid
                                container
                                justifyContent="space-evenly"
                                paddingTop={4}
                            >
                                {GenericButton('Read More', '/about')}
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledHomePanelContent>
            </StyledHomePanel>

            {/*Projects & Publications Panel*/}
            <StyledHomePanel sx={{ backgroundColor: 'background.default' }}>
                <StyledHomePanelContent>
                    <Grid container justifyContent="center" spacing={10}>
                        <Grid item lg={3}>
                            <Typography
                                variant="h2"
                                color="text.primary"
                                align="right"
                            >
                                PROJECTS
                                <br />
                                /
                                <br />
                                WRITING
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={8}
                            container
                            justifyContent="center"
                            spacing={4}
                        >
                            {featuredProjectCards}
                            <Grid item container justifyContent="flex-end">
                                {/*TODO: Center button when width is small*/}
                                {GenericButton('View All', '/projects')}
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledHomePanelContent>
            </StyledHomePanel>

            <Divider variant="middle" />

            {/*Get In Touch Panel*/}
            <StyledHomePanel
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: 'fit-content'
                }}
            >
                <StyledHomePanelContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={4}>
                            <Typography
                                variant="h2"
                                color="text.primary"
                                align="center"
                                marginBottom={theme.spacing(3)}
                            >
                                GET IN TOUCH
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                align="center"
                            >
                                Always down for trading fun facts, lame jokes or
                                book recommendations.
                            </Typography>
                            <Box paddingTop={6}>{SocialMediaIcons()}</Box>
                        </Grid>
                    </Grid>
                </StyledHomePanelContent>
            </StyledHomePanel>
        </ThemeProvider>
    )
}

export default HomePage
