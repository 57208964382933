import React from "react";
import {
  ThemeProvider,
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { theme } from "../utils/Theme";


const StyledProjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 0,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));


function ProjectCard(project: any) {
  return (
    <ThemeProvider theme={theme}>
      <StyledProjectCard>
        <CardContent sx={{ padding: theme.spacing(4, 4, 0) }}>
          <Typography variant="body1" textTransform="uppercase" color={theme.palette.primary.dark}>
            {project.category}
          </Typography>
          <Typography variant="h4" textTransform="uppercase" fontWeight="400" marginTop={1} marginBottom={2}>
            {project.title}
          </Typography>
          {/*TODO: Replace screen.width with theme.breakpoints*/}
          {/*TODO: Make card expandable for small screens*/}
          {
            screen.width > 600
              ? <Typography variant="body1">
                {project.description}
              </Typography>
              : null
          }
        </CardContent>
        <Box sx={{ padding: theme.spacing(1, 4) }}>
          <IconButton sx={{ float: "right" }} href={"/projects/" + project.url}>
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </StyledProjectCard>

    </ThemeProvider>
  );
}

export default ProjectCard;
