import { createTheme } from '@mui/material/styles';
import './Theme.css'

const theme = createTheme({
  palette: {
    common: {
      black: '#1D1C1B',
      white: '#F8F8F8'
    },
    primary: {
      main: '#8A817C',
      light: '#9F9793',
      dark: '#776E69'
    },
    secondary: {
      main: '#BCB8B1',
      light: '#C7C3BD',
      dark: '#B4AFA7'
    },
    text: {
      primary: '#534B46',
      secondary: '#F8F8F8',
    },
    background: {
      default: '#F8F8F8',
      paper: '#F8F8F8'
    }
  },
  typography: {
    fontFamily: "'Roboto Mono', 'Roboto'",
    h1: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: 64
    },
    h2: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: 48
    },
    h3: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: 36
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: 30
    },
    h5: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: 24
    },
    h6: {
      fontFamily: "Roboto",
      fontWeight: 300,
      fontSize: 20
    },
    body1: {
      fontFamily: "Roboto Mono",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.6
    },
    body2: {
      fontFamily: "Roboto Mono",
      fontWeight: 300,
      fontSize: 14
    },
    button: {
      fontFamily: "Roboto Mono",
      fontWeight: 400,
      fontSize: 14
    }
  }
})

export { theme }