import React from 'react'
import { ThemeProvider, Grid, Typography, styled } from '@mui/material'
import { theme } from '../utils/Theme'
import { BackButton, ExternalButton } from './Buttons'
import DOMPurify from 'dompurify'

const StyledResumeBackground = styled(Grid)({
    [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(12, 36, 12)
    },
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(8, 4)
    },
    backgroundColor: 'background.default',
    display: 'flex',
    justifyContent: 'center'
})

function ProjectPage(project: any) {
    return (
        <ThemeProvider theme={theme}>
            <StyledResumeBackground>
                <Grid
                    container
                    maxWidth="1000px"
                    display="flex"
                    flexDirection="column"
                >
                    <Grid item paddingBottom={4}>
                        {BackButton('Back', '/projects')}
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body1"
                            textTransform="uppercase"
                            color={theme.palette.primary.dark}
                            marginBottom={0.5}
                        >
                            {project.category}
                        </Typography>
                        <Typography
                            variant="h3"
                            textTransform="uppercase"
                            color="text.primary"
                            fontWeight="400"
                            marginBottom={2}
                        >
                            {project.title}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            align="justify"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(project.writeup)
                            }}
                        />
                    </Grid>
                    {project.button_text ? (
                        <Grid item paddingTop={4}>
                            {ExternalButton(
                                project.button_text,
                                project.button_url
                            )}
                        </Grid>
                    ) : null}
                </Grid>
            </StyledResumeBackground>
        </ThemeProvider>
    )
}

export default ProjectPage
