import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import AboutPage from './AboutPage'
import AllProjectsPage from './AllProjectsPage'
import ProjectPage from './components/ProjectPage'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Load all projects from .json file and create a <Route> element for each project
const allProjects = require('./content/allProjects.json')
const projectRoutes = allProjects.map((project: any) => (
    <Route path={'/projects/' + project.url} element={ProjectPage(project)} />
))

// Function that allows for automatic scrolling to the top every time you land on a page
function ScrollToTop() {
    const { pathname } = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return null
}

// Main component
const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/projects" element={<AllProjectsPage />} />
                {projectRoutes}
            </Routes>
        </Router>
    </React.StrictMode>
)
