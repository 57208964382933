import React from 'react'
import {
    ThemeProvider,
    Box,
    Card,
    Grid,
    Typography,
    styled
} from '@mui/material'
import { theme } from './utils/Theme'
import {
    BackButton,
    GenericButton,
    SocialMediaIcons
} from './components/Buttons'
import DotsMobileStepper from './components/MobileStepper'

const StyledAboutBackground = styled(Grid)({
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(12, 24, 12)
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(8, 4)
    },
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
})

const quotes = [
    {
        person: 'Ex-Form Teacher',
        quote: `Samantha is a remarkable young woman with drive and vision. [She] has shown herself to be an outstanding, extremely versatile and intelligent individual who is totally committed to excelling in her pursuits.`
    },
    {
        person: 'Another Ex-Form Teacher',
        quote: `A young lady who exudes sophistication and poise.`
    },
    {
        person: 'Ex-Internship Mentor',
        quote: `They don't make so much like [her] anymore. [She] reminds me so much of a real New Yorker-smart, brazen (in a good kind-of-way) and efficient.`
    }
]

function AboutPage() {
    return (
        <ThemeProvider theme={theme}>
            <StyledAboutBackground>
                <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Grid item paddingBottom={2}>
                        {BackButton('Home', '/')}
                    </Grid>
                    <Grid item paddingBottom={4}>
                        {/*TODO: Replace screen.width with theme.breakpoints*/}
                        {screen.width <= 600 ? (
                            <Typography
                                variant="h2"
                                textTransform="uppercase"
                                color="text.primary"
                            >
                                About
                            </Typography>
                        ) : (
                            <Typography
                                variant="h1"
                                textTransform="uppercase"
                                color="text.primary"
                            >
                                About
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        display="flex"
                        flexDirection="row"
                        spacing={8}
                    >
                        <Grid item lg={5} xs={12}>
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                    boxShadow: 1,
                                    bgcolor: 'primary.light'
                                }}
                                alt="Sam and her dog."
                                src="./media/sam-and-her-dog.jpg"
                            />
                        </Grid>
                        <Grid item lg={7} xs={12}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                align="justify"
                                paddingBottom={4}
                            >
                                Hello, I'm Sam.
                                <br />
                                <br />
                                And this was going to be a perfectly good photo
                                for my website until my dog decided otherwise.
                                <br />
                                <br />
                                I'm currently a Linguistics and Psychology
                                senior at Nanyang Technological University who
                                seems headed for the road less travelled. As it
                                turns out, one opportunity led to another so
                                here I am looking forward to exploring the
                                intersection of linguistics, machine learning
                                and AI.
                                <br />
                                <br /> Outside of the serious stuff, you'll
                                probably find me reading non-fiction, practising
                                BJJ (a new sport!) or dancing like no one's
                                watching. I'm also very occasionally funny.
                            </Typography>
                            {/*{GenericButton('Read Resume', '/about/resume')}*/}
                        </Grid>
                    </Grid>
                    {/*<Grid item paddingTop={8}>*/}
                    {/*    <Typography*/}
                    {/*        variant="h5"*/}
                    {/*        textTransform="uppercase"*/}
                    {/*        textAlign="center"*/}
                    {/*        sx={{ color: theme.palette.primary.main }}*/}
                    {/*    >*/}
                    {/*        Read what others have to say...*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    paddingTop={3}*/}
                    {/*    display="flex"*/}
                    {/*    justifyContent="center"*/}
                    {/*    alignItems="center"*/}
                    {/*>*/}
                    {/*    {DotsMobileStepper(quotes)}*/}
                    {/*</Grid>*/}
                    <Grid item paddingTop={8}>
                        <Card
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                borderRadius: 0,
                                padding: theme.spacing(4)
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight="400"
                                textTransform="uppercase"
                            >
                                About This Site
                            </Typography>
                            <Typography align="justify" paddingTop={2}>
                                The best thing my ex-boss (now mentor and
                                friend) Debbie did was inspire me with her drive
                                and smarts. The second best thing she did,
                                though, was gift me this domain name.
                                <br />
                                <br />I didn't know the first thing about coding
                                back then but insisted that I was going to build
                                my website from scratch. Oh boy, little did I
                                know what I'd signed up for. Anyway, plenty of
                                procrastination, a gap year, many late nights
                                and much trial-and-error later, I've finally got
                                this website going.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        paddingTop={8}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography
                            variant="h5"
                            color="text.primary"
                            textTransform="uppercase"
                            align="center"
                        >
                            Let's Connect
                        </Typography>
                        <Box paddingTop={2} width="20%" alignItems="center">
                            {SocialMediaIcons()}
                        </Box>
                    </Grid>
                </Grid>
            </StyledAboutBackground>
        </ThemeProvider>
    )
}

export default AboutPage
