import React from 'react'
import { ThemeProvider, Grid, Typography, styled } from '@mui/material'
import { theme } from './utils/Theme'
import ProjectCard from './components/ProjectCard'
import { BackButton } from './components/Buttons'

const StyledProjectsBackground = styled(Grid)({
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(12, 18, 12)
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(8, 4)
    },
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
})

// Load all projects from .json file
const allProjects = require('./content/allProjects.json')
const projectCards = allProjects.map((project: any) => (
    <Grid item xs={12} md={6} lg={4}>
        {ProjectCard(project)}
    </Grid>
))

function AllProjectsPage() {
    return (
        <ThemeProvider theme={theme}>
            <StyledProjectsBackground>
                <Grid container display="flex" flexDirection="column">
                    <Grid item paddingBottom={2}>
                        {BackButton('Home', '/')}
                    </Grid>
                    <Grid item paddingBottom={4}>
                        {/*TODO: Replace screen.width with theme.breakpoints*/}
                        {screen.width <= 600 ? (
                            <Typography
                                variant="h2"
                                textTransform="uppercase"
                                color="text.primary"
                            >
                                Projects
                                <br />
                                /<br />
                                Writing
                            </Typography>
                        ) : (
                            <Typography
                                variant="h1"
                                textTransform="uppercase"
                                color="text.primary"
                            >
                                Projects / Writing
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="flex-start"
                        spacing={4}
                    >
                        {projectCards}
                    </Grid>
                </Grid>
            </StyledProjectsBackground>
        </ThemeProvider>
    )
}

export default AllProjectsPage
