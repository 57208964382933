import React from 'react'
import {
    ThemeProvider,
    Button,
    Box,
    IconButton,
    Tooltip,
    styled
} from '@mui/material'
import { theme } from '../utils/Theme'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'

const StyledGenericButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    border: 'None',
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none'
    }
}))

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: 'text.secondary',
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: theme.spacing(1, 1)
    }
}))

const IconButtonStyling = {
    fontSize: 36,
    color: 'primary.main'
}

function visitURL(event: React.SyntheticEvent<any>, href: string) {
    event.preventDefault()
    window.location.href = href
}

function visitExternalURL(event: React.SyntheticEvent<any>, href: string) {
    window.open(href, '_blank')
}

export function GenericButton(text: string, url: string) {
    // const navigate = useNavigate()
    return (
        <ThemeProvider theme={theme}>
            {/*TODO: Sort out routing with useNavigate*/}
            <StyledGenericButton onClick={(e) => visitURL(e, url)}>
                {text}
            </StyledGenericButton>
        </ThemeProvider>
    )
}

export function ExternalButton(text: string, url: string) {
    // const navigate = useNavigate()
    return (
        <ThemeProvider theme={theme}>
            {/*TODO: Sort out routing with useNavigate*/}
            <StyledGenericButton onClick={(e) => visitExternalURL(e, url)}>
                {text}
            </StyledGenericButton>
        </ThemeProvider>
    )
}

export function BackButton(text: string, to_url: string) {
    // const navigate = useNavigate();
    return (
        <ThemeProvider theme={theme}>
            {/*TODO: Sort out routing with useNavigate*/}
            <Button
                startIcon={<ArrowBackIcon />}
                href={to_url}
                sx={{ paddingX: '0px' }}
            >
                {text}
            </Button>
        </ThemeProvider>
    )
}

export function SocialMediaIcons() {
    return (
        <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="space-evenly">
                <StyledToolTip title="samanthayomjy@gmail.com">
                    <IconButton
                        onClick={(e) =>
                            visitURL(
                                e,
                                "mailto:samanthayomjy@gmail.com?subject=Let's Connect Sam 👋🏻"
                            )
                        }
                    >
                        <EmailIcon sx={IconButtonStyling} />
                    </IconButton>
                </StyledToolTip>
                <StyledToolTip title="samantha-yom">
                    <IconButton
                        onClick={(e) =>
                            visitExternalURL(
                                e,
                                'https://www.linkedin.com/in/samantha-yom'
                            )
                        }
                    >
                        <LinkedInIcon sx={IconButtonStyling} />
                    </IconButton>
                </StyledToolTip>
                <StyledToolTip title="@sambutternoguns">
                    <IconButton
                        onClick={(e) =>
                            visitExternalURL(
                                e,
                                'https://www.instagram.com/sambutternoguns/'
                            )
                        }
                    >
                        <InstagramIcon sx={IconButtonStyling} />
                    </IconButton>
                </StyledToolTip>
            </Box>
        </ThemeProvider>
    )
}
